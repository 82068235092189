<template>
  <div id="app" :data-version="version">
    <div class="absolute-center">
      <div class="loader" :class="{ loaded: !isLoading }">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading" :class="{ 'loaded animated fadeIn': !isLoading }">
      <Header></Header>
      <main>
        <div class="headerRounded">
          <let-it-snow v-if="canSnow" v-bind="snowConf" :show="letItSnow" />
          <div class="headerRounded-background" :style="styles" @mousemove="moveBackground($event)"></div>
        </div>

        <router-view />
      </main>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from './components/Modules/Header'
import Footer from './components/Modules/Footer'

import LetItSnow from 'vue-let-it-snow'

// Helpers
import locale from './helpers/locale'
import { setAdminColor } from '@/helpers/styles'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.env.VUE_APP_BUILDING_NAME,
      meta: [
        { property: 'title', content: this.env.VUE_APP_BUILDING_NAME },
        { property: 'description', content: this.env.VUE_APP_BUILDING_NAME },

        { hid: 'description', name: 'description', content: this.env.VUE_APP_BUILDING_NAME },
        { hid: 'og:type', name: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.env.VUE_APP_BUILDING_NAME },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: this.env.VUE_APP_BUILDING_NAME,
        },
        { hid: 'og:url', name: 'og:url', property: 'og:url', content: this.env.VUE_APP_URL },
        { hid: 'og:locale', name: 'og:locale', property: 'og:locale', content: this.env.VUE_APP_I18N_LOCALE },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.env.VUE_APP_BUILDING_NAME,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: `${this.env.VUE_APP_ASSETS}/building-square.jpg`,
        },

        { property: 'og:url', content: this.env.VUE_APP_URL },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: this.env.VUE_APP_BUILDING_NAME },
        { property: 'og:description', content: this.env.VUE_APP_BUILDING_NAME },
        {
          property: 'og:image',
          content: `${this.env.VUE_APP_ASSETS}/building-square.jpg`,
        },
      ],
    }
  },

  data() {
    return {
      isLoading: true,
      letItSnow: false,
      locale,
    }
  },
  computed: {
    ...mapGetters(['env', 'settings', 'version']),

    canSnow() {
      const month = locale.getMonthNumber()

      return this.env.VUE_APP_ENABLED_SNOWING && this.isSWP && (month === '1' || month === '12')
    },

    snowConf() {
      const config = {
        windPower: 5,
        speed: 1,
        count: 50,
        size: 10,
        opacity: 1,
        images: [
          require('@/assets/images/icons/snow-1.png'),
          require('@/assets/images/icons/snow-2.png'),
          require('@/assets/images/icons/snow-3.png'),
          require('@/assets/images/icons/snow-5.png'),
        ],
      }

      if (this.settings.project.partner === null) {
        config.images.push(require('@/assets/images/icons/snow-4.png'))
      }

      return config
    },

    styles() {
      const baseURL = this.env.VUE_APP_CLOUDFRONT_URL

      return {
        '--background-webp': `url(${this.env.VUE_APP_ASSETS}/building-square.webp)`,
        '--background-avif': `url(${this.env.VUE_APP_ASSETS}/building-square.avif)`,
        '--background-jpg': `url(${this.env.VUE_APP_ASSETS}/building-square.jpg)`,
      }
    },

    isSWP() {
      return this.settings && this.settings.general.app_family.toUpperCase() === 'SWP'
    },
  },
  components: {
    Header,
    Footer,
    LetItSnow,
  },
  mounted() {
    setAdminColor()
    this.getSettings()

    setTimeout(() => {
      this.letItSnow = this.canSnow
    }, 1000)
  },

  methods: {
    getSettings() {
      this.$api
        .request({
          url: '/settings',
          method: 'GET',
        })
        .then(res => {
          this.$store.commit('SET_SETTINGS', res.data.data)
          this.isLoading = false
        })
    },

    moveBackground(event) {
      const bg = event.target
      const x = (window.innerWidth - event.pageX) / 90
      const y = (window.innerHeight - event.pageY) / 90

      bg.style.transform = `translateX(${x}px) translateY(${y}px)`
    },
  },
}
</script>
